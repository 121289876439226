import { render, staticRenderFns } from "./CurrentSummaryPanel.vue?vue&type=template&id=3640170b&scoped=true&"
import script from "./CurrentSummaryPanel.vue?vue&type=script&lang=ts&"
export * from "./CurrentSummaryPanel.vue?vue&type=script&lang=ts&"
import style0 from "./CurrentSummaryPanel.vue?vue&type=style&index=0&id=3640170b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3640170b",
  null
  
)

export default component.exports