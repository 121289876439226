
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { ECharts } from "echarts";
import { getChartColors } from "@/utils/tools";

echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

type EChartsOption = echarts.ComposeOption<
TooltipComponentOption | LegendComponentOption | PieSeriesOption
>;
@Component
export default class CurrentSummaryPanel extends Vue {
  @Prop({
    default: 0,
  })
  public type!: number;

  @Prop({
    default: () => [],
  })
  public votedList!: { [key: string]: string | number }[];

  @Prop({
    default: 0,
  })
  public total!: number;

  @Prop({
    default: () => [],
  })
  public userDataList!: { [key: string]: string | number }[];

  private colors: string[] = [];

  private pieChart: ECharts | null = null;

  private colorStyle(index: number) {
    if (this.colors.length === 0) {
      return {};
    }
    return {
      color: this.colors[index],
    };
  }

  private setupCharts() {
    this.colors = getChartColors(this.votedList.length);
    const chartOptions: EChartsOption = {
      tooltip: {
        trigger: "item",
      },
      color: [...this.colors],
      series: [
        {
          emphasis: {
            label: {
              show: false,
            },
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          type: "pie",
          radius: ["85%", "95%"],
          avoidLabelOverlap: false,
          data: this.votedList,
        },
      ],
    };
    if (this.pieChart) {
      this.pieChart.setOption(chartOptions);
    }
  }

  private tapVotedItem(item: any) {
    console.log(item);
    if (item.address.length > 0) {
      this.$router.push(`/addVote/${item.address}`);
    }
  }

  private mounted() {
    echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

    const chartDom = document.getElementById("pie-container");
    if (chartDom) {
      this.pieChart = echarts.init(chartDom);
      if (this.votedList.length > 0) {
        this.setupCharts();
      }
    }
  }

  @Watch("votedList")
  private voteListChange() {
    this.setupCharts();
  }
}
