
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

export interface IInputData {
  type: string;
  value: string;
  fee: string;
  extraData: { [key: string]: string | number };
}

@Component
export default class ReviewLockModal extends Vue {
  @Prop({
    default: false,
  })
  public value!: boolean;

  @Prop({
    default: {
      type: "Lock",
      value: "0",
      fee: "0",
      extraData: { name: "" },
    },
  })
  public inputData!: IInputData;

  public input(value: boolean) {
    this.$emit("input", value);
  }

  public tapConfirm() {
    this.$emit("confirm");
  }
}
